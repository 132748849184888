<template>
    <div class="page">
        <app-header :navIndex="1"></app-header>
        <banner :data="banner"></banner>
        <section class="section-container top-box">
            <div class="top-border hidden-md-and-up white"></div>
            <!--<div class="desc mt-md-12 mb-md-5 pa-5">{{banner.desc}}</div>-->
            <div ref="itemBox" class="item-box mt-md-12 mb-10 mb-md-12">
                <div class="line-box d-flex flex-column align-center">
                    <div class="circle"></div>
                    <div ref="line" class="line"></div><!-- :style="'height:'+lineHeight+'px'"-->
                    <div class="circle"></div>
                </div>
                <div class="item d-flex flex-column align-center" v-for="(items,index) in list"
                     :key="index">
                    <div class="year-box text-center white--text pb-md-10">
                        {{items.title}}<span>{{items.title % 10 == 0 ? 's' : ''}}</span></div>
                    <div class="d-flex flex-row justify-center align-center pt-md-8 col-sm-12"
                         v-for="(item,i) in items.items"
                         :key="i"
                         :class="i==items.items.length-1?'pb-md-10':''">
                        <div class="img-box col-md-5 d-flex flex-column"
                             :class="index==0?'order-1':(i%2==0?'order-0 align-end':'order-1')">
                            <v-img contain :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                            <div class="col-md-5 other-img" v-if="item.otherImgUrl">
                                <v-img contain :src="$utils.formatImgUrl(item.otherImgUrl)"></v-img>
                            </div>
                        </div>
                        <div class="col-md-4 text-box"
                             :class="`${index==0?'order-0 ':(i%2==0?'order-1 ':'order-0 ')}${item.otherImg?'text-box1 ':''}`">
                            <div class="year" :class="i%2==0?(index==0?'text-right':'text-left'):'text-right'">
                                {{item.year}}
                            </div>
                            <div class="pb-md-9 item-title"
                                 :class="i%2==0?(index==0?'text-right':'text-left'):'text-right'">{{item.title}}
                            </div>
                            <div class="desc" :class="i%2==0?(index==0?'text-right':'text-left'):'text-right'">
                                {{item.desc}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="bottom text-center pa-md-12 pa-5">
                 TO BE CONTINUED
             </div>-->
        </section>
    </div>
</template>
<script>
    import Banner from "../../components/base/banner";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'company/history/bg.jpg',
                    title: this.$i18n.t('others.history.title'),
                    enTitle: '',
//                    desc: 'ASC精品酒业扎根中国二十余载，已从最初的家族企业，成长为如今在中国大陆及港澳地区的精品葡萄酒进口行业的代表。欢迎访问本页面，了解我们的成长历程，关注公司未来发展：'
                },
                list: [
                    {
                        title: '1996',
                        items: [{
                            imgUrl: 'company/history/img_1996.jpg',
                            year: '1996',
                            title: 'ASC established',
                            desc: 'Americans Don St. Pierre, Sr. and Don St. Pierre, Jr. founded ASC Fine Wines in Beijing. 25,000 bottles were sold in their first year of business.',
                        }]
                    }, {
                        title: '2000',
                        items: [{
                            imgUrl: 'company/history/img_2006.jpg',
                            year: '2006',
                            title: '10th anniversary & WSET launched',
                            desc: "10th Anniversary and 2.7 million bottles sold in one year.  ASC was named “Finest Wine Distributor in China” at the 2006 China International Wine Challenge. The world's leading wine education organization, the Wine & Spirit Education Trust (WSET), appointed ASC Fine Wines as the first provider of WSET courses in China.",
                        }, {
                            imgUrl: 'company/history/img_2009_01.jpg',
                            year: '2009',
                            title: 'ASC Charity Fund established',
                            desc: "ASC is a responsible corporate citizen that gives back to the community where it is. ASC organized the first charity auction and raised RMB700,000 to establish the ASC Charity Fund under Shanghai Charity Foundation.",
//                            otherImgUrl:'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/history/img_2009.jpg',
                        }]
                    }, {
                        title: '2010',
                        items: [{
                            imgUrl: 'company/history/img_2010.png',
                            year: '2010',
                            title: 'Suntory acquisition',
                            desc: "ASC was acquired by Suntory, the global leader of beverage business and became a part of the Suntory Group, well-resourced for the development into future in the market.",
                        }, {
                            imgUrl: 'company/history/img_2011.jpg',
                            year: '2011',
                            title: 'National coverage completed (whole country, all channels)',
                            desc: "ASC started its business mainly in the international on-trade, then to off-trade, Direct Sales and wholesales channels. In terms of geographic coverage, ASC started in Beijing, then other key economic cities Mainland China, Hongkong and Macau.  After 15 years’ hard work, now ASC has it footprint in all the channels and across China by itself and through its partners.",
                        }, {
                            imgUrl: 'company/history/img_2012.jpg',
                            year: '2012',
                            title: 'Launch of E-Commerce',
                            desc: "With its pioneering spirit, ASC was among the very first players to bring wine business to e-commerce. ASC launched its e-commerce department this year.",
                        }, {
                            imgUrl: 'company/history/img_2016.jpg',
                            year: '2016',
                            title: 'ASC celebrates its 20th anniversary',
                            desc: "On April 26 ASC Fine Wines celebrated its 20th anniversary and signed the strategic partnership agreement with Alibaba to promote online wine sales and consumer appreciation of wine culture.",
                        }, {
                            imgUrl: 'company/history/img_2017.jpg',
                            year: '2017',
                            title: 'Portfolio expansion to include premium Chinese wine',
                            desc: "ASC added Grace Vineyard, a pioneer of Chinese domestic fine wine producers to its portfolio, which from then on includes both imported and locally produced fine wines.",
                            otherImgUrl: 'company/history/img_2017_01.jpg',
                        }]
                    }],
                lineHeight: 0
            }
        },
        created(){
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "发展历程"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getHistory({}).then(res => {
                    if (res.rows.length > 0) {
                        this.list = res.rows
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>

    .item-box {
        position: relative;
        .item {
            position: relative;
            z-index: 1;
            .year-box {
                width: 200px;
                height: 200px;
                line-height: 200px;
                background-color: $primary-color;
                border-radius: 50%;
                font-size: 60px;
                span {
                    font-size: 40px;
                }
            }
            .text-box {
                .year {
                    font-size: 40px;
                    color: $primary-color;
                }

                .item-title {
                    font-size: 20px;
                    color: $primary-color;
                    line-height: 30px;
                }
            }
            .text-box1 {
                margin-top: -120px;
            }
        }
        .line-box {
            @include lb();
            top: 210px;
            height: calc(100% - 210px);
            .circle {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $primary-color;
            }
            .line {
                width: 1px;
                height: 100%;
                background-color: $primary-color;
            }
        }
    }

    .bottom {
        font-size: 30px;
    }

    @media (max-width: $screen-md) {
        .item-box {
            .item {
                .year-box {
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    font-size: 30px;
                    span {
                        font-size: 20px;
                    }
                }
                .img-box {
                    width: 50%;
                    .other-img {
                        width: 50%;
                    }
                }
                .text-box {
                    width: 50%;
                    .year {
                        font-size: 30px;
                    }
                    .item-title {
                        font-size: 10px;
                        line-height: 20px;
                    }
                }
            }
            .line-box {
                @include lb();
                top: 110px;
                height: calc(100% - 110px);
                .circle {
                    width: 10px;
                    height: 10px;
                }
            }
        }
        .bottom {
            font-size: 20px;
        }
    }

</style>
